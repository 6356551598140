<template>
  <el-card
    :shadow="props.shadow"
    :style="cardStyle"
    class="unselect"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLevel">
    <div class="card-body">
      <div class="card-icon" :style="{ 'background-color': props.bgColor }">
        <ui-svg :svg-code="svg" :svg-color="props.svgColor" :disable-mouse-hover="true" />
      </div>

      <div class="flex-column" style="gap: 2px; justify-content: space-between">
        <div class="card-number">{{ toMoney(props.value, props.displayFloat) }}</div>
        <div class="card-desc">{{ props.desc }}</div>
      </div>
    </div>
  </el-card>
</template>

<script setup>
  import { defineProps, computed, ref } from 'vue'
  import { toMoney } from '@/utils'

  const props = defineProps({
    svg: String,
    svgHover: String,
    svgColor: String,
    bgColor: String,
    value: Number,
    displayFloat: {
      type: Number,
      default: 0,
    },
    desc: String,
    width: {
      type: [Number, String],
      default: 220,
    },
    shadow: {
      type: String,
      // "always" | "never" | "hover"
      default: 'hover',
    },
  })

  const is_hover = ref(false)

  const svg = computed(() => {
    return is_hover.value ? props.svgHover : props.svg
  })

  const cardStyle = computed(() => {
    return {
      'min-width': props.width + 'px',
    }
  })

  function onMouseEnter() {
    is_hover.value = true
  }

  function onMouseLevel() {
    is_hover.value = false
  }
</script>

<style scoped lang="scss">
  .card-body {
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    gap: 14px;
  }

  .card-icon {
    border-radius: 25%; /* 将正方形变成圆形 */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }

  .card-number {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    height: 22px;
    color: #000000;
  }

  .card-desc {
    font-size: 12px;
    color: #7a858b;
    line-height: 12px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }
</style>
